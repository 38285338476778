<template>
    <div class="foot-wrapper">
        <span>@{{company}}</span>
        <span>||{{describe}}||</span>
        <span>@{{others}}</span>
    </div>
</template>

<script>


export default {
    name:'FootWrapper',
    props:{
        company:"",
        describe:"",
        others:""
    }
}
</script>
<style lang="less">
.foot-wrapper{
    width: 100%;
    height:57.78px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: gray;
}
</style>