<template>
  <el-container>
    <el-scrollbar>
      <el-header>
        <head-wrapper>
          <template #icon>
            <!-- <img id="logoimg" src="../assets/logo.png" alt="../assets/logo.png"> -->
          </template>
          <template #text>
            <span id="title-text">食品安全信息监管平台</span>
          </template>
        </head-wrapper>
      </el-header>
      <el-main>
        <!-- <div id="mainpic">
          <img
            src="@/assets/pictures/loginmainpic.gif"
          />
        </div> -->
        <div id="loginArea">
          <router-view>
          </router-view>
        </div>
      </el-main>
    </el-scrollbar>
  </el-container>
</template>

<script>
import HeadWrapper from "component/Head.vue";
import FootWrapper from "component/Foot.vue";
export default {
  components: {
    HeadWrapper,
    FootWrapper,
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height:100vh;
  width:100vw;
  background-color: #6fa3f0;
  // background-image: url("pic/loginback.jpg");
  background-image: url("../../assets/pictures/login1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  .el-scrollbar{
      width:100%;
      height:100%;
  }
}
.el-header {
  // box-shadow:var(--el-box-shadow-base);
  position:sticky;
  top:0px;
  padding: 0;

  #title-text{
    font-size:32px;
  }
}
.el-footer {
  padding: 0;
  height: auto;
}
.el-main {
  display: flex;
  flex-direction: row;
  width:100%;
  height: calc(100vh - 57.76px);
  padding: 0;
  #mainpic {
    height: 100%;
    flex: 0 1 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      flex: 0 0 700px;
      height: 500px;
    }
  }
  #loginArea {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

#logoimg {
  width: 60px;
  height: 57px;
  flex: 0 0;
  border-radius: 8px;
}
#title-text {
  font-size: 26px;
  font-weight: bold;
  text-shadow: 1px 1px 1px #3c6797;
  color: white;
}
</style>